import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [5,2,3,4];

export const dictionary = {
		"/": [~6],
		"/login": [15],
		"/logout": [~16],
		"/payment/success": [~17],
		"/privacy": [18],
		"/terms": [19],
		"/tutor/edit": [20,[5]],
		"/tutor/edit/plans": [21,[5]],
		"/tutor/edit/trial": [~22,[5]],
		"/tutor/edit/workdays": [23,[5]],
		"/tutor/invite": [24,[5]],
		"/tutor/me": [~25,[5]],
		"/tutor/me/payments": [~26,[5]],
		"/tutor/squad/[squad]": [~27,[5]],
		"/tutor/username": [28,[5]],
		"/[tutor]": [7,[2]],
		"/[tutor]/invite": [14,[2]],
		"/[tutor]/[plan]": [~8,[2,3]],
		"/[tutor]/[plan]/book": [~9,[2,3]],
		"/[tutor]/[plan]/class/[cls]": [10,[2,3,4]],
		"/[tutor]/[plan]/class/[cls]/complete": [11,[2,3,4]],
		"/[tutor]/[plan]/class/[cls]/edit": [~12,[2,3,4]],
		"/[tutor]/[plan]/order": [~13,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';