import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENV } from '$env/static/public';
import '@formatjs/intl-enumerator/polyfill';

Sentry.init({
  dsn: 'https://f26ee1e29247f283be58ea00416f7357@o4507159780589568.ingest.de.sentry.io/4507159802478672',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],

  beforeSend(event) {
    event.tags = {
      ...event.tags,
      service: 'frontend'
    };
    return event;
  },

  environment: PUBLIC_ENV
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
